<template>
    <div>
        <h1>{{ $t('site_search') }}</h1>
        <div class="input-wrapper">
            <div class="input-field">
                <b-field label="Id сайта">
                    <b-input 
                        type="text" 
                        v-model="siteId" 
                        placeholder="Введите id сайта"
                        :disabled="!!leadEmail.length">
                    </b-input>
                </b-field>
            </div>
            
            <div class="or-text">или</div>

            <div class="input-field">
                <b-field label="Email лида">    
                    <b-input 
                        type="email" 
                        v-model="leadEmail" 
                        placeholder="Введите email лида"
                        :disabled="!!siteId.length">
                    </b-input>
                </b-field>
            </div>

            <button 
                type="button" 
                class="button is-primary"
                :disabled="isLoading || (!siteId.length && !leadEmail.length)"
                @click="searchSite"
                >
                <span>Поиск</span>
            </button>
        </div>
        
        <b-loading :is-full-page="true" :active='isLoading'></b-loading>
        
        <div v-if="error" class="notification is-danger">
            {{ error }}
        </div>
        
        <div v-if="sites.length" class="search-results">
            <h2>Результаты поиска</h2>
            <table class="table is-fullwidth is-striped">
                <thead>
                    <tr>
                        <th>ID сайта</th>
                        <th>Домен</th>
                        <th>Активен</th>
                        <th>Индустрия</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="site in sites" :key="site.id">
                        <td>{{ site.id }}</td>
                        <td>
                            <a :href="site.domain" target="_blank" rel="noopener noreferrer">
                                {{ site.domain }}
                            </a>
                        </td>
                        <td>{{ site.is_active ? 'Да' : 'Нет' }}</td>
                        <td>{{ site.industry || 'Не указана' }}</td>
                        <td>
                            <router-link :to="'/sites/' + site.id + '/settings'" >
                                <button type="button" class="button is-primary is-small">
                                    Настройки
                                </button>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div class="pagination-info">
                <p>Страница {{ pagination.current_page }} из {{ pagination.total_pages }}</p>
                <div class="pagination-controls">
                    <button 
                        class="button is-small" 
                        :disabled="!pagination.has_prev" 
                        @click="changePage(pagination.current_page - 1)">
                        Предыдущая
                    </button>
                    <button 
                        class="button is-small" 
                        :disabled="!pagination.has_next" 
                        @click="changePage(pagination.current_page + 1)">
                        Следующая
                    </button>
                </div>
            </div>
        </div>
        
        <div v-else-if="searched && !isLoading" class="notification is-warning">
            Сайты не найдены
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SiteSearch',
    data() {
        return {
            siteId: '',
            leadEmail: '',
            isLoading: false,
            sites: [],
            pagination: {
                current_page: 1,
                total_pages: 1,
                has_next: false,
                has_prev: false
            },
            error: null,
            searched: false,
            perPage: 10
        }
    },
    methods: {
        ...mapActions(['searchSiteByIdOrEmail']),
        
        async searchSite(page = 1) {
            if (!this.siteId && !this.leadEmail) return
            
            this.isLoading = true
            this.error = null
            
            try {
                const result = await this.searchSiteByIdOrEmail({
                    site_id: this.siteId,
                    lead_email: this.leadEmail,
                    page: page,
                    per_page: this.perPage
                })
                
                if (result.success) {
                    this.sites = result.data.sites
                    this.pagination = result.data.pagination
                    this.searched = true
                } else {
                    this.error = result.message
                    this.sites = []
                }
            } catch (error) {
                this.error = 'Произошла ошибка при поиске'
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
        
        changePage(page) {
            this.searchSite(page)
        }
    }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    width: 30%;
    margin-top: 24px;
    margin-bottom: 24px;
    .input-field {
        margin-bottom: 16px;
    }
    .or-text {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.search-results {
    margin-top: 30px;
    
    h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
}

.pagination-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    
    .pagination-controls {
        display: flex;
        gap: 10px;
    }
}
</style>